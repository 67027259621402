import React, { useEffect } from "react";
import s from "./ErrorServerRefreshStub.module.css";
import logo from "../../assets/images/logo.png";

const twoMinutesInMs = 120000;

const ErrorServerRefreshStub = ({ language }) => {

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, twoMinutesInMs)
  }, []);

  return (
    <div className={s.container}>
      <a href="https://goit.ua/" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="logo" />
      </a>

      <p className={s.text}>
      Технические работы на сервере, сервер перезагружается, обновите страницу через 1-2 минуты.
      </p>
    </div>
  );
};

export default ErrorServerRefreshStub;