export default function blockDefinition(blockHash) {
  let blockIndex = 0;
  if (blockHash === "nop678917") {
    blockIndex = 1;
  } else if (blockHash === "yz01abc36") {
    blockIndex = 2;
  } else if (blockHash === "p67892319") {
    blockIndex = 3;
  } else if (blockHash === "678923420") {
    blockIndex = 4;
  } else if (blockHash === "892345q22") {
    blockIndex = 5;
  } else if (blockHash === "92345qr23") {
    blockIndex = 6;
  } else if (blockHash === "2345qrs24") {
    blockIndex = 7;
  } else if (blockHash === "345qrst25") {
    blockIndex = 8;
  } else if (blockHash === "45qrstu26") {
    blockIndex = 9;
  } else if (blockHash === "5qrstuv27") {
    blockIndex = 10;
  } else if (blockHash === "tuvwxyz31") {
    blockIndex = 11;
  } else if (blockHash === "z01abcd37") {
    blockIndex = 12;
  } else if (blockHash === "01abcde38") {
    blockIndex = 13;
  } else if (blockHash === "uvwxyz032") {
    blockIndex = 14;
  } else if (blockHash === "wxyz01a34") {
    blockIndex = 15;
  } else if (blockHash === "1abcdef39") {
    blockIndex = 16;
  }

  return blockIndex;
}
