import React, { Component } from "react";
import ResizableWrapper from "../../components/ResizableWrapper/ResizableWrapper";
import CustomCodeMirror from "../CustomCodeMirror/CustomCodeMirror";
import styles from "./CodeEditors.module.css";
import utilsFunctionBlockDefinition from "../../utils/blockDefinition";

class CodeEditors extends Component {
  state = {
    showHTML: true,
  };

  handleshowHTML = () => {
    this.setState({ showHTML: true });
  };
  handleshowCSS = () => {
    this.setState({ showHTML: false });
  };

  render() {
    const {
      valueHTML,
      valueCSS,
      handleChangeHMTL,
      handleChangeCSS,
      metadata,
      notEditableHtmlBlocks,
      notEditableCssBlocks,
      htmlHlLines,
      cssHlLines,
      currentTaskId,
      isRefreshCurrentQuestion,
      htmlCursor,
      cssCursor,
      activityTimeMonitoringInCodeEditor,
      block,
    } = this.props;

    const blockIndex = utilsFunctionBlockDefinition(block);

    return (
      <div className={styles.code_container}>
        <>
          <div
            style={
              metadata.disableHtmlEditor
                ? metadata.needCss
                  ? { minWidth: "40%" }
                  : { width: "100%" }
                : metadata.needCss
                ? { maxWidth: "60%", minWidth: "40%", width: "60%" }
                : { width: "100%" }
            }
            className={styles.box}
          >
            <h4
              className={
                metadata.disableHtmlEditor
                  ? styles.disableCodeTitle
                  : styles.codeTitle
              }
            >
              {metadata.codemirrorTitle
                ? metadata.codemirrorTitle
                : "index.html:"}
            </h4>
            <div className={styles.html_box} id="html_box">
              <CustomCodeMirror
                mode="html"
                code={valueHTML}
                onChangeCode={handleChangeHMTL}
                notEditableBlocks={notEditableHtmlBlocks}
                hlLines={htmlHlLines}
                propertyReadOnly={metadata && metadata.disableHtmlEditor}
                currentTaskId={currentTaskId}
                isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                positionCursor={htmlCursor}
                activityTimeMonitoringInCodeEditor={
                  activityTimeMonitoringInCodeEditor
                }
              />
            </div>
          </div>
          {metadata && metadata.needCss && (
            <ResizableWrapper
              propertyReadOnly={metadata && metadata.disableCssEditor}
            >
              <div className={styles.box}>
                <h4
                  className={
                    metadata.disableCssEditor
                      ? styles.disableCodeTitle
                      : styles.codeTitle
                  }
                >
                  styles.css:
                </h4>
                <div className={styles.css_box} id="css_box">
                  <CustomCodeMirror
                    mode={blockIndex === 14 ? "scss" : "css"}
                    code={valueCSS}
                    onChangeCode={handleChangeCSS}
                    notEditableBlocks={notEditableCssBlocks}
                    hlLines={cssHlLines}
                    propertyReadOnly={metadata && metadata.disableCssEditor}
                    currentTaskId={currentTaskId}
                    isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                    positionCursor={cssCursor}
                    activityTimeMonitoringInCodeEditor={
                      activityTimeMonitoringInCodeEditor
                    }
                  />
                </div>
              </div>
            </ResizableWrapper>
          )}
        </>
      </div>
    );
  }
}

export default CodeEditors;
