import React from "react";
import styles from "./TaskButtons.module.css";
import { ReactComponent as Refresh } from "../../assets/icons/autorenew-black-18dp.svg";
// import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
// import Timer from "../Timer/Timer";
// import VideoModal from "../VideoModal/VideoModal";
import localization from "../../utils/localization";

const TaskButtons = ({
  checkTest,
  // timerEndTime,
  // metadata,
  showTimer,
  // hideTimer,
  // clientWidth,
  openModalRefreshQuestion,
  language,
}) => {
  // const [isShowVideoModal, setIsShowVideoModal] = useState(false);

  // const showVideoModal = () => {
  //   if (!showTimer) {
  //     setIsShowVideoModal(true);
  //   }
  // };
  // const closeVideoModal = () => setIsShowVideoModal(false);

  return (
    <div className={styles.buttonsContainer}>
      <button className={styles.checkButton} onClick={checkTest}>
        {language === "ru"
          ? localization.TaskButtons.btnCheck.ru
          : localization.TaskButtons.btnCheck.ua}
      </button>

      <button
        onClick={openModalRefreshQuestion}
        className={styles.buttonWithSvg}
      >
        <Refresh className={styles.svg} />
        {language === "ru"
          ? localization.TaskButtons.btnRefresh.ru
          : localization.TaskButtons.btnRefresh.ua}
      </button>

      {/* {(clientWidth > 1300 || !showTimer) && (
        <button
          onClick={showVideoModal}
          className={
            !showTimer ? styles.buttonWithSvg : styles.disableButtonWithSvg
          }
        >
          <Movie className={!showTimer ? styles.svg : styles.disableSvg} />
          {language === "ru"
            ? localization.TaskButtons.btnVideo.ru
            : localization.TaskButtons.btnVideo.ua}
        </button>
      )} */}

      {/* {showTimer && <Timer language={language} timerEndTime={timerEndTime} hideTimer={hideTimer} />} */}

      {/* {isShowVideoModal && (
        <VideoModal metadata={metadata} onClose={closeVideoModal} />
      )} */}
    </div>
  );
};

export default TaskButtons;
