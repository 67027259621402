import React from "react";
import T from "prop-types";
import Frame from "react-frame-component";

function FrameComponent({ children, width = "100%" }) {
  return (
    <Frame
      initialContent='<!DOCTYPE html><html><head><link href="https:fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet"><style>body,a{font-family:"Montserrat",sans-serif;};</style><style>*::-webkit-scrollbar{width:5px;height:5px;}::-webkit-scrollbar-track-piece{background-color:transparent;}::-webkit-scrollbar-thumb{height:5px;background-color:rgba(193,193,193,0.5);border-radius:10px;}</style><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
      integrity="sha256-PHcOkPmOshsMBC+vtJdVr5Mwb7r0LkSVJPlPrp/IMpU=" crossorigin="anonymous"/></head><body><div><div></body></html>'
      style={{
        width: width,
        height: "36vh",
        border: "solid 1px #c1c1c1",
        overflowY: "auto",
        fontFamily: "'Montserrat', sans-serif",
        paddingTop: "32px",
        background: "#fff",
        minHeight: "180px",
      }}
    >
      {children}
    </Frame>
  );
}

FrameComponent.propTypes = {
  children: T.object.isRequired,
};

export default FrameComponent;

// <meta HTTP-EQUIV="CACHE-CONTROL" CONTENT="NO-CACHE">
//<meta HTTP-EQUIV="PRAGMA" CONTENT="NO-CACHE">
