import React from "react";
import { Resizable } from "re-resizable";

const ResizableWrapper = ({ children, propertyReadOnly = false }) => {
  const resizableWidth = propertyReadOnly ? "40%" : "60%";
  const style = {
    width: resizableWidth,
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    // border: "solid 1px #ddd",
    //   background: "#f7f7f7",
    backgroundColor: "rgba(193, 193, 193, 0.5)",
    paddingLeft: "1%",
    //   boxSizing: "border-box",
  };

  return (
    <Resizable
      minWidth="40%"
      maxWidth="60%"
      defaultSize={{
        width: resizableWidth,
        height: "38vh",
      }}
      // maxHeight="50vh"
      // minHeight="50vh"
      // lockAspectRatio="true"
      // bounds="parent"
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      style={style}
    >
      {children}
    </Resizable>
  );
};

export default ResizableWrapper;
