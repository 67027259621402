const optionsHMTL = {
  mode: "htmlmixed",
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: false,
  lineWrapping: true,
  autohint: true,
  tabMode: "indent",

  autoCloseTags: true,
  foldGutter: true,
  dragDrop: true,
  lint: true,
  extraKeys: {
    "Ctrl-Space": "autocomplete",
  },
  gutters: [
    "CodeMirror-lint-markers",
    "CodeMirror-linenumbers",
    "CodeMirror-foldgutter",
  ],
};

const optionsCSS = {
  mode: "css",
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: false,
  lineWrapping: true,
  autohint: true,
  tabMode: "indent",

  autoCloseTags: true,
  foldGutter: true,
  dragDrop: true,
  lint: true,
  extraKeys: {
    "Ctrl-Space": "autocomplete",
  },
  gutters: [
    "CodeMirror-lint-markers",
    "CodeMirror-linenumbers",
    "CodeMirror-foldgutter",
  ],
};

const optionsJS = {
  mode: "javascript",
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: false,
  lineWrapping: true,
  autohint: true,
  tabMode: "indent",

  autoCloseTags: true,
  foldGutter: true,
  dragDrop: true,
  lint: true,
  extraKeys: {
    "Ctrl-Space": "autocomplete",
  },
  gutters: [
    "CodeMirror-lint-markers",
    "CodeMirror-linenumbers",
    "CodeMirror-foldgutter",
  ],
};

const optionsSCSS = {
  mode: "text/x-scss",
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: false,
  lineWrapping: true,
  autohint: true,
  tabMode: "indent",

  autoCloseTags: true,
  foldGutter: true,
  dragDrop: true,
  lint: true,
  extraKeys: {
    "Ctrl-Space": "autocomplete",
  },
  gutters: [
    "CodeMirror-lint-markers",
    "CodeMirror-linenumbers",
    "CodeMirror-foldgutter",
  ],
};

export default {
  optionsHMTL,
  optionsCSS,
  optionsJS,
  optionsSCSS,
};
