import React, { Component } from "react";
import Frame from "react-frame-component";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import BigBrowserHeader from "../BigBrowserHeader/BigBrowserHeader";
import s from "./BrowserResultModal.module.css";

class BrowserResultModal extends Component {
  componentDidMount() {
    // in order for writing online js code to work and it works in our browser - start
    const scriptsPrev = document
      .querySelector("#browser-result-container")
      .querySelector("iframe")
      .contentDocument.querySelector("body")
      .querySelectorAll("script");
    if (scriptsPrev.length > 0) {
      scriptsPrev.forEach((el) => el.remove());
    }

    const { valueJS } = this.props;

    setTimeout(() => {
      const script = document.createElement("script");
      script.textContent = `(function () {
        ${valueJS}
       })()`;
      script.async = true;
      document
        .querySelector("#browser-result-container")
        .querySelector("iframe")
        .contentDocument.querySelector("body")
        .appendChild(script);
    }, 500);
    // in order for writing online js code to work and it works in our browser - end
  }

  render() {
    const {
      onClose,
      valueCSS,
      resultView,
      openModalRefreshQuestion,
      isMobileViewSelected,
      selectMobileView,
      cancelSelectMobileView,
      isTabletViewSelected,
      selectTabletView,
      cancelTabletView,
      compiledValueCss,
    } = this.props;
    return (
      <ModalWrapper onClose={onClose}>
        <div className={s.wrapper} id="browser-result-container">
          <BigBrowserHeader
            openModalRefreshQuestion={openModalRefreshQuestion}
            onClose={onClose}
            isMobileViewSelected={isMobileViewSelected}
            selectMobileView={selectMobileView}
            cancelSelectMobileView={cancelSelectMobileView}
            isTabletViewSelected={isTabletViewSelected}
            selectTabletView={selectTabletView}
            cancelTabletView={cancelTabletView}
          />
          <Frame
            initialContent='<!DOCTYPE html><html><head><link href="https:fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet"><style>body,a{font-family:"Montserrat",sans-serif;};</style><style>*::-webkit-scrollbar{width:5px;height:5px;}::-webkit-scrollbar-track-piece{background-color:transparent;}::-webkit-scrollbar-thumb{height:5px;background-color:rgba(193,193,193,0.5);border-radius:10px;}</style></head><body><div><div></body></html>'
            style={{
              width: isMobileViewSelected
                ? "360px"
                : isTabletViewSelected
                ? "560px"
                : "100%",
              height: "80vh",
              border: "solid 1px #c1c1c1",
              overflowY: "auto",
              fontFamily: "'Montserrat', sans-serif",
              background: "#fff",
            }}
          >
            <div>
              <style>{compiledValueCss ? compiledValueCss : valueCSS}</style>
              <div dangerouslySetInnerHTML={resultView} />
            </div>
          </Frame>
        </div>
      </ModalWrapper>
    );
  }
}

export default BrowserResultModal;
